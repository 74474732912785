import { H, Section } from "@jfrk/react-heading-levels";
import { TextContent } from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import * as defaultStyles from "@whitespace/gatsby-theme-wordpress-basic/src/components/Article.module.css";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import * as layout from "../foundation/layout.module.css";

NotFoundPage.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.string),
};

export default function NotFoundPage({ className, styles = defaultStyles }) {
  return (
    <article
      className={clsx(layout.component, layout.componentWidthFull, className)}
    >
      <div className={clsx(layout.component, layout.componentWidthNarrow)}>
        <H>Sidan hittades inte (404)</H>
        <Section>
          <TextContent>
            <p>
              Sidan du försökte nå kunde tyvärr inte hittas. Här hittar du några
              möjliga förklaringar till detta.
            </p>
            <H>Fel adress?</H>
            <p>
              Om du har skrivit in adressen för hand kan det lätt bli fel. Börja
              med att kontrollera att du skrev in rätt adress och försök igen.
            </p>
            <H>Gamla länkar?</H>
            <p>
              Vi har nyligen byggt en ny webbplats, vilket medför att vissa
              länkar till sidor i den gamla webbplatsen inte längre fungerar.
              För att hitta motsvarande sida i den nya webbplatsen, gå till
              startsidan och använd menyn för att hitta det du söker. Vad gör
              jag nu? För att komma tillbaka till föregående sida kan du använda
              din webbläsares tillbakaknapp.
            </p>
          </TextContent>
        </Section>
      </div>
    </article>
  );
}
